/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
// @import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Import custom font */
@import "./assets/fonts/roboto/font-roboto";

@import 'swiper/scss';

// Import JSON Editor CSS
// @import "~jsoneditor/dist/jsoneditor.min.css";


// @import "~jsoneditor/dist/jsoneditor.min.css";

:root {
  color-scheme: only light;
}

ion-modal.modal-default.show-modal:last-of-type {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-content {
  --background: rgb(--mocli-transparent);
}

.global-btn {
  user-select: none;
  color: white;
  font-size: 12px;
  font-family: 'Roboto medium';
  cursor: pointer;
  border-radius: 15px;


  text-align: center;


  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.40);
  transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out, transform 0.2s ease-out, opacity 0.2s ease-out;
  /* Smooth transition */
  user-select: none;

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }


  &-definitive {
    padding: 6px 30px;
    background-color: #FFB649;
  }

  &-cancel {
    padding: 6px 20px;
    background-color: var(--mocli-danger);

    &:hover {
      background-color: #bb414c;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.60);
      /* Stronger box-shadow on hover */
    }

    &:active {
      background-color: #9e363e;
      transform: scale(0.95);
      /* Button scales down slightly on click */
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.30);
      /* Box shadow reduces on click */
    }

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: var(--mocli-danger) !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.40) !important;
      }

      &:active {
        background-color: var(--mocli-danger) !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.40) !important;
        transform: none !important;
      }
    }
  }


  &-save {
    padding: 6px 20px;
    background-color: var(--ion-color-dark);

    &:hover {
      background-color: #002247;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.60);
      /* Stronger box-shadow on hover */
    }

    &:active {
      background-color: #00152b;
      transform: scale(0.95);
      /* Button scales down slightly on click */
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.30);
      /* Box shadow reduces on click */
    }

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: var(--ion-color-dark) !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.40) !important;
      }

      &:active {
        background-color: var(--ion-color-dark) !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.40) !important;
        transform: none !important;
      }
    }
  }

  &-back {
    padding: 6px 20px;
    background-color: var(--mocli-success);

    &:hover {
      background-color: #0069D7;
      /* Darker shade on hover */
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.60);
      /* Stronger box-shadow on hover */
      // transform: translateY(-2px); /* Slight lift effect */
    }

    &:active {
      transform: scale(0.95);
      /* Button scales down slightly on click */
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.30);
      /* Box shadow reduces on click */
      background-color: #004A99;
      /* Slightly darker shade on click */
    }

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: #0069D7 !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.40) !important;
      }

      &:active {
        background-color: #0069D7 !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.40) !important;
        transform: none !important;
      }
    }
  }
}

.data-content-header-disabled {
  padding: 7px 1em !important;
  //     padding-top: 6px !important;
  //     padding-bottom: 6px !important;
}

.p-datepicker-other-month {
  color: white !important;
  user-select: none !important;
}

#accordion-stats-explorer a {
  color: var(--ion-color-dark) !important;
}

.p-toast {
  font-size: 15px !important;
  width: 19.5rem !important;
}

.p-toast .p-toast-message {
  border-radius: 20px !important;
  font-family: 'Roboto regular' !important;
}

.p-toast-detail {
  margin: 0 !important;
}

.p-toast-icon-close {
  display: none !important;
}

.p-toast-message-icon {
  align-self: center !important;
}

.p-toast-message-success {
  background: #E0FFC1 !important;
  border: solid transparent;
  color: #466F1F !important;
}

.p-toast-message-warn {
  background: #FFFCAA !important;
  border: solid transparent;
  // color: #7E7A08 !important;
}

.p-toast-message-error {
  background: #FFC1C1 !important;
  border: solid transparent;
  color: #A32D2D !important;
}

.p-toast-message-info {
  background: #D7F6FF !important;
  border: solid transparent;
  color: #19647C !important;
}

.swiper-autoheight,
.swiper-autoheight,
.swiper-slide {
  height: fit-content !important;
}

.p-dialog {
  border: none !important;
}

.p-dialog-content {
  padding: 0 !important;
  border-radius: 15px !important;
}

// .mySwiper {
//   .swiper-wrapper {
//     height: unset !important;
//   }
// }

.swiper-dashboard {
  .swiper-slide {
    height: 100% !important;
  }
}

.full-width {
  width: 100%;
}

.split-gridcontainer {
  display: block;
  width: 100%;

  .split-block {
    float: left;
    display: inline-block;
    padding: 10px;
  }

  .block-left {
    width: 75%;
    ;
  }

  .block-right {
    width: 25%;
  }
}

.page-title {
  // color: var(--ion-color-medium);
  font-size: 24pt;
  width: 100%;
  font-weight: 500;
  margin: 10px 35px;
}

.divider {
  width: 90%;
  margin-left: 0;
  height: 1px;
  background-color: var(--ion-color-medium-tint);
}

.card-title-small {
  font-size: 10pt;
  height: 35px !important;
  --min-height: 35px !important;
}

.card-content-small {
  padding: 5px !important;
}

// Containers

.mocli-container-main {
  background-color: var(--ion-color-primary-bg-transparent);
  border-radius: 15px;
  padding: 22px;
}

.mocli-container-content {
  background-color: var(--mocli-bg-content);
  border-radius: 15px;
  padding: 22px;
}

.mocli-box-content {
  background-color: var(--ion-color-light);
  border-radius: 15px;
  height: 100%;

  padding: 22px;
}

.page-splitter,
.page-splitter-phone {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 24px;

  display: grid;

  >div {
    margin: 0 24px;
    height: fit-content;
  }

  >div:nth-child(1) {
    max-width: 850px;
    justify-self: center;
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr !important;
  }
}


.page-splitter {
  grid-template-columns: 1fr 350px;
}

.page-splitter-phone {
  grid-template-columns: 1fr 490px;
  // @media (min-width: 1441px) {
  //   grid-template-columns: 1fr 400px !important;
  // }
}


// Transform this
// - Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus consequatur nam optio tempore minus commodi
// Into this
// - Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus...
.nowrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-card {
  margin-inline: 0 !important;
}

.mocli-button {
  padding: 10px 30px;
  color: white;
  background-color: var(--ion-color-dark);
  width: fit-content;
  border-radius: 28px;
  font-family: 'Roboto bold';
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  filter: brightness(100%);
  transition: opacity 0.2s ease-in, box-shadow 0.2s ease-in;
  cursor: pointer;
  box-shadow: none;


  &-disabled {
    cursor: not-allowed !important;
    opacity: 0.4 !important;
    color: white !important;

    background-color: var(--ion-color-medium);

    &:hover {
      outline: none !important;

      a {
        color: white !important;
      }
    }
  }

  &:hover {
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
    opacity: 0.7;
  }

  &:active {
    background-color: var(--ion-color-medium);
    color: var(--ion-color-dark);
  }

  &.small {
    padding: 5px 15px;
    font-size: 14px;
  }
}

.modal-wrapper,
.modal-shadow,
.ion-overlay-wrapper {
  max-width: 160px !important;
  max-height: 60px !important;
}

// .modal-confirm-custom {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translateY(-50%) translateX(-50%);

//     min-width: 300px !important;
//     max-width: 500px !important;

//     min-height: 200px !important;
//     max-height: 350px !important;

//     border-radius: 15px;
// }

.modal-feedback,
.modal-hint,
.modal-scanner-error {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: calc(100% - 40px);
    max-width: 600px;
    height: auto;
    border-radius: 15px;
    background-color: white;
  }
}

.modal-simple-date-picker {

  align-items: center;
  justify-content: center;

  &::part(content) {
    height: fit-content;
    width: fit-content;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-login {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: fit-content;
    min-width: 300px;
    height: fit-content;
    max-width: 600px;
    border-radius: 15px;
    background-color: white;
  }
}

.modal-text-info {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: calc(100% - 40px);
    max-width: 600px;
    height: fit-content;
    border-radius: 15px;
    background-color: white;
  }
}

.modal-codehunt-guide {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: calc(100% - 40px);
    max-width: 600px;
    height: calc(100% - 80px);
    border-radius: 15px;
    background-color: white;
  }
}

.modal-edit-object-name {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 400px;
    height: fit-content;
    max-height: 600px;
    overflow-y: auto;
    // min-height: 150px;
    border-radius: 15px;
    background-color: #F4FAFF;
  }
}

.modal-confirm-custom {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 420px;
    height: fit-content;
    // min-height: 150px;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-sub-exp-configurator {
  align-items: center;
  justify-content: center;

  &::part(content) {
    overflow: visible;
    width: 775px;
    min-height: 690px;
    height: fit-content;
    border-radius: 15px;
    background-color: #ffffff;
  }
}

.modal-confirm-contract-rpgd {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: calc(100vw - 200px);
    max-width: 1000px;
    height: fit-content;
    // min-height: 600px;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-add-stat,
.modal-language,
.modal-add-category,
.modal-create-custom-theme {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: auto;
    height: auto;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-data-config {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: auto;
    height: fit-content;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-sticker-repartition {
  &::part(content) {
    width: auto;
    height: auto;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-preview-template-support {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: auto;
    height: auto;
    max-height: 550px;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-add-order-option {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: auto;
    height: auto;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-confirm-qr {
  &::part(content) {
    height: 340px !important;
  }
}

.modal-preview-experience {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 500px;
    max-height: calc(100vh - 40px);
    height: 900px;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-order-validate {
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 700px;
    height: 410px;
    border-radius: 15px;
    background-color: #eef5fd;
    border: 1px solid rgba(0, 40, 82, 0.3);
  }

  --ion-backdrop-color: rgba(255, 255, 255, 0.98) !important;
}

.modal-buy-credits {
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  &::part(content) {
    margin: 0;
    width: 500px;
    height: auto;
    border-radius: 15px;
    background-color: #F4FAFF;
    border: 1px solid rgba(0, 40, 82, 0.3);
    overflow-y: auto;
    transition: margin 0.2s ease;
  }

  --ion-backdrop-color: transparent !important;

  @media screen and (max-height: 850px) {
    &::part(content) {
      margin-top: 200px !important;
      margin-bottom: 50px !important;
    }
  }
}


.modal-update-adress {
  backdrop-filter: blur(3px);
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  &::part(content) {
    margin: 0;
    width: 500px;
    height: 440px;
    border-radius: 15px;
    background-color: #F4FAFF;
    border: 1px solid rgba(0, 40, 82, 0.3);
    overflow-y: auto;
    transition: margin 0.2s ease;
  }

  --ion-backdrop-color: transparent !important;
}


/* ---------- Achat de crédits / Slider ---------- */

.p-slider {
  background-color: #ffb64969 !important;
  cursor: pointer;
}

.p-slider-range {
  background-color: #ffb649 !important;
}

#slider_credits .p-slider-handle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 100% !important;
  background-color: var(--mocli-danger);
  border: 2px solid var(--mocli-danger);

  &:focus {
    box-shadow: 0 0 0 0.2rem #FF9D8780;
  }
}


.modal-tutorial-catalog {
  backdrop-filter: blur(3px);

  &::part(content) {
    width: 662px;
    height: 480px;
    border-radius: 15px;
    background-color: #eef5fd;
  }

  --ion-backdrop-color: transparent !important;
}

.modal-url-socials-tooltip {
  backdrop-filter: blur(3px);

  &::part(content) {
    min-width: 620px;
    min-height: 170px;
    height: fit-content;
    border-radius: 15px;
    background-color: #eef5fd;
  }

  --ion-backdrop-color: transparent !important;
}

// #statsLanguageSelectorPanel {
//   background: transparent !important;
//   border-radius: 15px !important;

//   .p-dropdown-items-wrapper {
//     background-color: #ECF5FF !important;
//     border: none !important;
//     border-radius: 15px !important;
//   }

//   .p-dropdown-item {
//     line-height: 28px !important;
//     padding: 0 !important;
//     font-family: 'Roboto medium' !important;
//     font-size: 12px !important;
//     color: var(--ion-color-dark) !important;
//     text-align: center;
//   }

//   .p-highlight {
//     background-color: #0075f133 !important;
//   }

//   .p-focus {
//     box-shadow: none !important;
//   }
// }



#statsPeriodSelector,
#statsLanguageSelector {
  .p-dropdown {
    background: #ECF5FF !important;
    border: none !important;
    height: 28px !important;
    padding: 0 10px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;
    min-width: 200px;

    font-family: 'Roboto medium';
    font-size: 13px;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #ECF5FF !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }
}

#statsAnswersRateSelector {
  .p-dropdown {
    margin-top: 10px;
    background: transparent !important;
    border: 1px solid #002852 !important;
    height: 28px !important;
    padding: 0 10px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;
    min-width: 80px;

    font-family: 'Roboto medium';
    font-size: 13px;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #ECF5FF !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }
}

#statsAnswersRateContainer {
  #statsAnswersRateSelectorCodeHunt {
    max-width: calc(100% - 96px);
  }
}

#statsAnswersRateSelectorCodeHunt {
  p-dropdown {
    width: 100%;
  }

  .p-dropdown {
    // margin-top: 10px;
    background: transparent !important;
    border: 1px solid #002852 !important;
    height: 28px !important;
    padding: 0 10px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
    max-width: 100% !important;
    width: 100%;
    // overflow: hidden;
  }

  .p-dropdown-label {
    padding: 0 !important;
    min-width: 80px;

    font-family: 'Roboto medium';
    font-size: 13px;
    color: var(--ion-color-dark);


    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #ECF5FF !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }
}

#statsAnswersRateSelectorQuestionHunt {
  .p-dropdown {
    width: 90px;
    // margin-top: 10px;
    background: transparent !important;
    border: 1px solid #002852 !important;
    height: 28px !important;
    padding: 0 10px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
    // overflow: hidden;
  }

  .p-dropdown-label {
    padding: 0 !important;
    min-width: 30px;

    font-family: 'Roboto medium';
    font-size: 13px;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #ECF5FF !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }
}

#statsAnswersRateSelectorPanelCodeHunt {
  background: transparent !important;
  border-radius: 15px !important;

  .p-dropdown-items-wrapper {
    background-color: #ECF5FF !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    line-height: 28px !important;
    padding: 0 6px !important;
    max-width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto medium' !important;
    font-size: 12px !important;
    color: var(--ion-color-dark) !important;
    text-align: center;
  }

  .p-highlight {
    background-color: #0075f133 !important;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }
}

#statsAnswersRateSelectorPanel {
  background: transparent !important;
  border-radius: 15px !important;

  .p-dropdown-items-wrapper {
    background-color: #ECF5FF !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    line-height: 28px !important;
    padding: 0 !important;
    font-family: 'Roboto medium' !important;
    font-size: 12px !important;
    color: var(--ion-color-dark) !important;
    text-align: center;
  }

  .p-highlight {
    background-color: #0075f133 !important;
  }

  .p-focus {
    box-shadow: none !important;
  }
}



#checkBoxContainerSmall .container input {
  width: 13px !important;
  height: 13px !important;
}

#checkBoxContainerSmall .container span {
  width: 13px !important;
  height: 13px !important;
}

#checkBoxContainerSmall .container .checkmark:after {
  left: 3px !important;
  top: 0px !important;
  width: 3px !important;
  height: 6px !important;
  border-width: 0 1px 1px 0 !important;
}


#oddOneOutSelector {
  .p-dropdown {
    background: #ECF5FF !important;
    border: none !important;
    height: 28px !important;
    padding: 0 10px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;

    font-family: 'Roboto medium';
    font-size: 13px;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #ECF5FF !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }
}

#codeSelector {
  .p-dropdown {
    background: #F4F4F4 !important;
    border: none !important;
    height: 28px !important;
    padding: 0 10px;
    min-width: 160px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;
    margin: 0 10px 0 30px !important;
    min-width: 80px;

    font-family: 'Roboto regular';
    font-size: 13px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #F4F4F4 !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    opacity: 0.35 !important;
  }
}

#conditionSelector {
  .p-dropdown {
    background: #ededed !important;
    border: none !important;
    height: 28px !important;
    padding: 0 10px;
    min-width: 160px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;
    margin: 0 10px 0 30px !important;
    min-width: 80px;
    font-weight: 700;

    font-family: 'Roboto bold';
    font-size: 13px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #ededed !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #3d82cc33 !important;
  }

  .p-dropdown-item {
    padding: 0 6px;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    opacity: 0.35 !important;
  }
}

#mInput_select {
  p-dropdown {
    width: 100%;
    border: none !important;
    box-shadow: none !important;
  }

  .p-dropdown {
    width: 100%;
    background: transparent !important;
    border: 1px solid #D6D6D6;
    height: 28px !important;
    padding-left: 6px;
    min-width: 80px;
    line-height: 27px !important;
    border-radius: 7px !important;
    text-align: start;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    // &:focus-visible, &:focus {
    //   border: 1px solid var(--mocli-success) !important;
    //   box-shadow: 0px 0px 0px 2px rgba(0, 116, 241, 0.25) !important;
    // }
  }

  .p-dropdown-trigger {
    color: #C4C4C4 !important;
  }

  .p-dropdown-label {
    padding: 0 !important;
    // margin: 0 10px 0 30px !important;
    min-width: 0px;
    // margin-left: 10px !important;
    // font-weight: 700;

    font-family: 'Roboto regular';
    font-size: 12px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: #ffffff !important;
    border-radius: 7px !important;
  }

  .p-dropdown-items-wrapper {
    background: #ffffff !important;
    border-radius: 7px !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    background-color: #ffffff !important;
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #3d82cc33 !important;
  }

  .p-dropdown-item {
    padding: 0 0 0 10px !important;
    font-family: 'Roboto regular';
    font-size: 12px;
    line-height: 24px !important;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  p-dropdown .p-inputwrapper-focus {
    border: 1px solid var(--mocli-success) !important;
    box-shadow: 0px 0px 0px 2px rgba(0, 116, 241, 0.25) !important;
  }

  .p-disabled {
    opacity: 0.35 !important;
  }
}

#mInput_select-160px {
  .p-dropdown {
    background: transparent !important;
    border: 1px solid #D6D6D6 !important;
    height: 28px !important;
    padding-left: 6px;
    min-width: 80px;
    line-height: 27px !important;
    border-radius: 7px !important;
    text-align: start;
    min-width: 190px !important;
  }

  .p-dropdown-trigger {
    color: #C4C4C4 !important;
  }

  .p-dropdown-label {
    padding: 0 !important;
    // margin: 0 10px 0 30px !important;
    min-width: 0px;
    // margin-left: 10px !important;
    // font-weight: 700;

    font-family: 'Roboto regular';
    font-size: 12px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: #ffffff !important;
    border-radius: 7px !important;
  }

  .p-dropdown-items-wrapper {
    background: #ffffff !important;
    border-radius: 7px !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    background-color: #ffffff !important;
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #3d82cc33 !important;
  }

  .p-dropdown-item {
    padding: 0 0 0 10px !important;
    font-family: 'Roboto regular';
    font-size: 12px;
    line-height: 24px !important;
    color: var(--ion-color-dark);
    min-width: 162px;
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    opacity: 0.35 !important;
  }

  .p-dropdown-clear-icon {
    color: #C4C4C4 !important;
  }
}

#mInput_select-240px {
  .p-dropdown {
    background: transparent !important;
    border: 1px solid #D6D6D6 !important;
    height: 28px !important;
    padding-left: 6px;
    min-width: 80px;
    line-height: 27px !important;
    border-radius: 7px !important;
    text-align: start;
    min-width: 240px !important;
  }

  .p-dropdown-trigger {
    color: #C4C4C4 !important;
  }

  .p-dropdown-label {
    padding: 0 !important;
    // margin: 0 10px 0 30px !important;
    min-width: 0px;
    // margin-left: 10px !important;
    // font-weight: 700;

    font-family: 'Roboto regular';
    font-size: 12px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: #ffffff !important;
    border-radius: 7px !important;
  }

  .p-dropdown-items-wrapper {
    background: #ffffff !important;
    border-radius: 7px !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    background-color: #ffffff !important;
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #3d82cc33 !important;
  }

  .p-dropdown-item {
    padding: 0 0 0 10px !important;
    font-family: 'Roboto regular';
    font-size: 12px;
    line-height: 24px !important;
    color: var(--ion-color-dark);
    min-width: 240px;
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    opacity: 0.35 !important;
  }

  .p-dropdown-clear-icon {
    color: #C4C4C4 !important;
  }
}


#mInput_select-220px {
  .p-dropdown {
    background: transparent !important;
    border: 1px solid #D6D6D6 !important;
    height: 28px !important;
    padding-left: 6px;
    min-width: 80px;
    line-height: 27px !important;
    border-radius: 7px !important;
    text-align: start;
    min-width: 250px !important;
  }

  .p-dropdown-trigger {
    color: #C4C4C4 !important;
  }

  .p-dropdown-label {
    padding: 0 !important;
    // margin: 0 10px 0 30px !important;
    min-width: 0px;
    // margin-left: 10px !important;
    // font-weight: 700;

    font-family: 'Roboto regular';
    font-size: 12px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: #ffffff !important;
    border-radius: 7px !important;
  }

  .p-dropdown-items-wrapper {
    background: #ffffff !important;
    border-radius: 7px !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    background-color: #ffffff !important;
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #3d82cc33 !important;
  }

  .p-dropdown-item {
    padding: 0 0 0 10px !important;
    font-family: 'Roboto regular';
    font-size: 12px;
    line-height: 24px !important;
    color: var(--ion-color-dark);
    min-width: 162px;
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    opacity: 0.5 !important;
  }

  .p-dropdown-clear-icon {
    color: #C4C4C4 !important;
  }
}



#littleSelector {
  .p-dropdown {
    background: #ededed !important;
    border: none !important;
    height: 28px !important;
    padding: 0 10px;
    min-width: 80px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;
    margin: 0 10px 0 30px !important;
    min-width: 0px;
    font-weight: 700;

    font-family: 'Roboto regular';
    font-size: 13px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #ededed !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #3d82cc33 !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    opacity: 0.35 !important;
  }
}

#languageSelector {
  .p-dropdown {
    background: white !important;
    border: 1px solid transparent;
    height: 28px !important;
    padding: 0 10px;
    width: 175px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;
    margin: 0 10px 0 3px !important;
    min-width: 0px;
    font-weight: 700;

    font-family: 'Roboto regular';
    font-size: 13px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: white !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #3d82cc33 !important;
  }

  .p-dropdown-item {
    padding: 2px 0 2px 12px;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
    padding-left: 12px;
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    opacity: 0.35 !important;
  }
}

#prizesSelector {
  .p-dropdown {
    background: #ededed !important;
    border: none !important;
    height: 28px !important;
    padding: 0 10px;
    min-width: 200px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;
    margin: 0 10px 0 30px !important;
    min-width: 80px;

    font-family: 'Roboto bold';
    font-size: 13px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    min-width: 200px !important;
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #ededed !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: rgba(61, 130, 204, 0.092) !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    height: 28px;
    line-height: 28px !important;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    opacity: 0.35 !important;
  }
}

#font-selector {
  .p-dropdown {
    background: #F4F4F4 !important;
    border: 1px solid var(--ion-color-dark) !important;
    height: 22px !important;
    padding: 0 0 0 6px;
    line-height: 22px !important;
    max-width: 130px;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 0 2px 0 !important;
    margin: 0 0 0 15px !important;

    font-family: 'Roboto regular';
    font-size: 13px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #F4F4F4 !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0 6px !important;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    opacity: 0.35 !important;
  }

  .p-overlay {
    left: -30px !important;
  }
}

#categories-selector {
  .p-dropdown {
    background: #F4F4F4 !important;
    border: none !important;
    height: 28px !important;
    padding: 0 10px;
    min-width: 240px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;
    margin: 0 10px 0 30px !important;
    min-width: 120px;

    font-family: 'Roboto regular';
    font-size: 13px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #F4F4F4 !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    cursor: not-allowed !important;
    opacity: 0.6 !important;
  }
}


#exp-selector {
  .p-dropdown {
    background: #F4F4F4 !important;
    border: none;
    height: 28px !important;
    padding: 0 10px;
    min-width: 500px;
    line-height: 28px !important;
    border-radius: 15px !important;
    text-align: center;
  }

  .p-dropdown-label {
    padding: 0 !important;
    // margin: 0 10px 0 30px !important;
    min-width: 120px;

    font-family: 'Roboto regular';
    font-size: 13px;
    // text-align: left;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #F4F4F4 !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0 10px;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-disabled {
    cursor: not-allowed !important;
    opacity: 0.4 !important;
  }
}



.dropdown-error {
  .p-dropdown {
    border: 1px solid var(--mocli-danger) !important;
  }
}


#oddOneOutSelector .p-dropdown-items-wrapper {
  max-height: 125px !important;
}

#oddOneOutSelector .p-dropdown-label {
  min-width: 150px;
}

#adminSettingsOrderSlideMenu>p-slidemenu>div {
  // top: 198px !important;
  // margin-top: -105px;
  right: 0 !important;
  left: unset !important;
}

#adminOrdersMenu .p-slidemenu-wrapper,
#adminOrdersMenu ul {
  width: 100% !important;
}

#adminSettingsSlideMenu>p-slidemenu>div {
  // top: 90px !important;
  // margin-top: -181px;
  right: 0 !important;
  left: unset !important;
}

.modal-preview-image {
  &::part(content) {
    width: 400px;
    height: auto;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-stats_custom-period {
  &::part(content) {
    width: auto;
    height: fit-content;
    padding: 0 25px;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

.modal-crop-image {
  &::part(content) {
    width: auto;
    height: auto;
    border-radius: 15px;
    background-color: #eef5fd;
    transition: height 0.2s ease;
  }
}


.modal-date-picker {
  &::part(content) {
    width: auto;
    height: auto;
    border-radius: 15px;
    background-color: #eef5fd;
    transition: height 0.2s ease;
  }
}

.modal-edit-demo {
  &::part(content) {
    width: auto;
    height: auto;
    border-radius: 15px;
    background-color: #eef5fd;
    transition: height 0.2s ease;
  }
}

.modal-extend-experience {
  &::part(content) {
    width: 400px;
    height: 300px;
    border-radius: 15px;
    background-color: #eef5fd;
  }
}

#statsModalCustomPeriod {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--ion-color-dark);

  padding-bottom: 15px;

  // overflow-y: scroll;

  h1 {
    margin-bottom: 21px;
  }

  .datesList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;

    >.start-container,
    .end-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      >h2 {
        margin-bottom: 8px;
        font-size: 16px;
      }

      >h3 {
        font-size: 14px;
        margin-top: 0;
        color: var(--ion-color-dark-shade);
      }
    }
  }
}

.controls {
  margin-top: 21px;
  width: 100%;
  display: flex;
  justify-content: center;

}

.button {
  font-size: 13px;
  padding: 5px 10px;
}

.confirm {
  background-color: var(--mocli-success);
}

.cancel {
  background-color: var(--mocli-danger);
}

#tableSupports {
  .p-datatable-table {
    min-width: 500px !important;
  }
}

#tableProjects,
#tableSupports {

  .p-sortable-column,
  .p-datatable-thead>tr>th {
    border: none !important;
    font-family: 'Roboto bold';
    font-size: 16px;
    color: var(--ion-color-dark) !important;
  }

  .p-sortable-column-icon {
    color: var(--ion-color-dark) !important;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody>tr {

    td {
      border: none !important;
      font-family: 'Roboto regular';
      font-size: 13px;
      color: var(--ion-color-dark) !important;
      padding: 8px 1em !important;
    }

    &:nth-child(even) {
      background: transparent;
    }

    &:nth-child(odd) {
      -moz-border-radius: 15px !important;
      -webkit-border-radius: 15px !important;
      border-radius: 15px !important;
      background: var(--ion-color-primary-bg-transparent);

      td:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      td:last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }

  .p-datatable .p-sortable-column {
    &:focus {
      box-shadow: none;
      outline: 0 none;
    }

    &:hover {
      border-radius: 15px;
    }
  }

  .p-paginator {
    background: none;

    .p-link:focus {
      box-shadow: none;
      outline: 0 none;
    }

    .p-ripple {
      color: var(--ion-color-dark) !important;
      max-height: 1.8rem !important;
      min-width: 1.8rem !important;
      max-width: 1.8rem !important;
      font-size: 14px !important;
    }

    .pi {
      font-size: 14px !important;
    }

    .p-highlight {
      color: white !important;
      background-color: #0075F1 !important;
    }

    .p-paginator-first {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .p-paginator-last {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

#dataStockProjects {

  .p-sortable-column,
  .p-datatable-thead>tr>th {
    border: none !important;
    font-family: 'Roboto regular' !important;
    font-size: 13px;
    color: var(--ion-color-dark) !important;
  }

  th {
    padding: 1rem 1em;
  }

  td,
  th {
    overflow: hidden !important;
    white-space: nowrap !important;
  }

  .p-sortable-column-icon {
    color: var(--ion-color-dark) !important;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody>tr {

    td {
      border: none !important;
      font-family: 'Roboto regular';
      font-size: 13px;
      color: var(--ion-color-dark) !important;
      padding: 5px 1em !important;
    }

    &:nth-child(even) {
      background: transparent;
    }

    &:nth-child(odd) {
      -moz-border-radius: 15px !important;
      -webkit-border-radius: 15px !important;
      border-radius: 15px !important;
      background: var(--ion-color-primary-bg-transparent);

      td:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      td:last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }

  .p-datatable .p-sortable-column {
    &:focus {
      box-shadow: none;
      outline: 0 none;
    }

    &:hover {
      border-radius: 15px;
    }
  }

  .p-paginator {
    background: none;

    .p-link:focus {
      box-shadow: none;
      outline: 0 none;
    }

    .p-ripple {
      color: var(--ion-color-dark) !important;
      max-height: 1.8rem !important;
      min-width: 1.8rem !important;
      max-width: 1.8rem !important;
      font-size: 13px !important;
    }

    .pi {
      font-size: 13px !important;
    }

    .p-highlight {
      color: white !important;
      background-color: #0075F1 !important;
    }

    .p-paginator-first {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .p-paginator-last {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

#tableLeaderboard {

  .p-sortable-column,
  .p-datatable-thead>tr>th {
    border: none !important;
    font-family: 'Roboto light';
    font-size: 12px;
    // color: var(--ion-color-dark) !important;
    padding: 0 !important;
  }

  .p-sortable-column-icon {
    color: var(--ion-color-dark) !important;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody>tr {
    background: transparent;
    border-top: 1px solid #0074f17e;

    td {
      border: none !important;
      font-family: 'Roboto regular';
      font-size: 16px;
      // color: var(--ion-color-dark) !important;
      padding: 4px 0 !important;
    }

    // &:nth-child(even) {
    //   background: transparent;
    // }

    // &:nth-child(odd) {
    //   -moz-border-radius: 15px !important;
    //   -webkit-border-radius: 15px !important;
    //   border-radius: 15px !important;
    //   background: var(--ion-color-primary-bg-transparent);

    //   td:first-child { 
    //     border-top-left-radius: 15px; 
    //     border-bottom-left-radius: 15px; 
    //   }

    //   td:last-child { 
    //     border-top-right-radius: 15px; 
    //     border-bottom-right-radius: 15px; 
    //   }
    // }
  }

  .p-datatable .p-sortable-column {
    &:focus {
      box-shadow: none;
      outline: 0 none;
    }

    &:hover {
      border-radius: 15px;
    }
  }

  .p-paginator {
    background: none;

    .p-link:focus {
      box-shadow: none;
      outline: 0 none;
    }

    .p-ripple {
      color: var(--ion-color-dark) !important;
      max-height: 1.8rem !important;
      min-width: 1.8rem !important;
      max-width: 1.8rem !important;
      font-size: 14px !important;
    }

    .pi {
      font-size: 14px !important;
    }

    .p-highlight {
      color: white !important;
      background-color: #0075F1 !important;
    }

    .p-paginator-first {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .p-paginator-last {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}


.backdrop-no-scroll {
  ion-content {
    --overflow: auto;
  }

  .scroll-y {
    height: fit-content;
  }
}

.mocli-error {
  color: var(--ion-color-danger);
  font-style: italic;
  font-size: 11pt;
}

.dropdown-custom-error .p-dropdown {
  border: 1px solid var(--mocli-danger) !important;
}


.dropdown-adress,
.dropdown-card {

  .p-dropdown-label {
    padding: 0 !important;
    padding-left: 8px !important;

    font-family: 'Roboto regular';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-dropdown-panel {
    transform-origin: none !important;
    background: transparent !important;
    border-radius: 15px !important;
  }

  .p-dropdown-items-wrapper {
    background-color: #ECF5FF !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .p-dropdown-item {
    padding: 0;
    font-family: 'Roboto medium';
    font-size: 12px;
    color: var(--ion-color-dark);
  }

  .p-highlight {
    background-color: #0075f133;
  }

  .p-focus {
    box-shadow: none !important;
  }
}

.dropdown-adress {
  .p-dropdown {
    background: #C8D3DE !important;
    border: none !important;
    width: 103px !important;
    max-width: 103px !important;
    height: 20px !important;
    line-height: 20px !important;
    border-radius: 15px !important;
    text-align: left;
  }
}


.dropdown-card {
  .p-dropdown {
    background: #C8D3DE !important;
    border: none !important;
    width: 100% !important;
    height: 20px !important;
    line-height: 20px !important;
    border-radius: 15px !important;
    text-align: left;
  }
}

/* ----- Accordion FAQ ----- */

#accordionFaq {

  p-accordiontab a {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 10px;
    font-family: 'Roboto regular' !important;
    font-weight: 500;
    color: var(--ion-color-dark);
    font-size: 14px;
  }

  p-accordiontab .p-accordion-toggle-icon {
    transition: transform 0.2s ease-in-out;
    position: absolute;
    right: 0;
    transform: rotate(90deg);
  }

  p-accordiontab .pi-chevron-down {
    transform: rotate(180deg) !important;
  }

  p-accordiontab .p-accordion-content {
    border: none;
    background: transparent;
    color: var(--ion-color-dark);
    font-family: 'Roboto light';
    font-size: 13px;
    padding: 5px 10px 15px 10px;
  }
}


/* ----- Table wallet transactions ----- */

#tableWalletTransactions {

  .p-sortable-column,
  .p-datatable-thead>tr>th {
    border: none !important;
    font-family: 'Roboto bold';
    font-size: 16px;
    color: var(--ion-color-dark) !important;
    background: transparent !important;
    padding: 10px 0 10px 30px !important;
  }

  .p-sortable-column-icon {
    color: var(--ion-color-dark) !important;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody>tr {

    td {
      border: none !important;
      font-family: 'Roboto regular';
      font-size: 13px;
      color: var(--ion-color-dark) !important;
      padding: 10px 30px !important;
    }

    &:nth-child(even) {
      background: white;

      td:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      td:last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }

    &:nth-child(odd) {
      -moz-border-radius: 15px !important;
      -webkit-border-radius: 15px !important;
      border-radius: 15px !important;
      background: var(--ion-color-primary-bg-transparent);

      td:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      td:last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }

  .p-datatable .p-sortable-column {
    &:focus {
      box-shadow: none;
      outline: 0 none;
    }

    &:hover {
      border-radius: 15px;
    }
  }

  .p-paginator {
    background: none;
  }
}

#badge-notifications {
  position: absolute;
  bottom: 42px !important;
  right: 27px !important;

  span {
    background-color: var(--mocli-danger);
  }
}

#adminExperienceStructureLanguageSlideMenu>p-slidemenu>div {
  top: 112px !important;
  left: 23px !important;
}


.custom-icon {
  background-image: url('https://cdn.mocli.fr/icon/flags/fr.svg');
  background-size: cover;
  background-position: center;
  outline: 1px solid black;
  height: 25px;
  width: 25px;
  border-radius: 100%;
}

.dateSelectorClass {
  font-size: 12px !important;

  .p-disabled {
    opacity: 0.4 !important;
  }

  >.p-datepicker {
    width: 300px !important;

    table {
      font-size: 0.9rem !important;

      th {
        padding: 0.5rem 0 !important;

        >span {
          width: auto !important;
        }
      }

      td {
        padding: 0 !important;

        >span {
          width: auto !important;
        }
      }
    }
  }
}

#tabViewCommKit>p-tabView {
  .p-highlight>a {
    color: var(--mocli-success) !important;
  }

  li>a {
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

#tabViewThemeEditor>p-tabView {
  .p-tabview-panels {
    padding: 0 !important;
  }

  .p-highlight>a {
    color: var(--mocli-success) !important;
  }

  li>a {
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

#tabViewThemeEditor {
  .p-tabview-panels {
    // padding: 0 !important;
  }

  .p-highlight>a {
    color: var(--mocli-success) !important;
  }

  li>a {
    font-size: 14px !important;
    padding: 0.75rem 1.63rem;
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

#tableCommKit .tableRowCommKit {
  cursor: pointer !important;
}

.overscroll::before {
  bottom: 0px !important
}

.overscroll::after {
  top: 0px !important;
}

#previewSupport .ng-trigger-animation {
  height: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden !important;

  >img {
    max-height: 100% !important;
  }
}

#previewSupport {
  .p-image-preview-container {
    width: 100% !important;
    height: 100px;

    >img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

}

#tableAddElementSupport {

  tr:focus {
    outline: none !important;
  }
}

.disabled-input {
  cursor: not-allowed !important;
  color: #787878 !important;
  opacity: 0.6 !important;
}

#data-input-listbox {
  .p-highlight {
    color: initial !important;
    background-color: initial !important;
  }

  .p-listbox-item:focus {
    box-shadow: none !important;
  }

  .p-listbox-item:hover {
    background-color: initial !important;
  }
}

#configurator-orderlist {

  // Hide controls
  .p-orderlist-controls {
    display: none;
  }

  .p-orderlist .p-orderlist-list-container {
    background: transparent !important;
    border: none !important;
}

  .p-orderlist-list {
    background: transparent !important;
    outline: none !important;
    border: none !important;
    padding: 0 !important;
    min-height: 0 !important;
  }

  .p-highlight {
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.p-orderlist-item {
  padding: 0 !important;
  border-radius: 8px;
  margin: 0 10px !important;
  // margin-right: 15px !important;

  &:hover {
    // background-color: #0074f11b !important;
    background-color: transparent !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

#listBoxStatList {
  .p-listbox-item {
    padding: 0 !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.element {
  padding: 3px 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  position: relative;
  font-size: 12px;
  color: var(--ion-color-dark);
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  >.header-title {
    vertical-align: middle;
    font-family: 'Roboto Light Italic';
    font-style: italic;
  }

  &-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }

  &-checkbox {
    width: fit-content;
    min-width: 20px;
    display: flex;
    justify-content: center;
    margin-right: 15px;
  }

  >div {
    line-height: 20px;
    vertical-align: middle;
  }

  &:hover {
    background-color: #0074f11b;
  }

  &-selected {
    background-color: #0074f13f !important;

    &:hover {
      background-color: #0074f13f !important;
    }
  }

  .first {
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding-left: 12px;

    .element-drag-icon {
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
      height: 18px;
      width: 18px;
      cursor: move;

      >img {
        max-width: 100%;
      }
    }

    .element-text {
      // padding-left: 8px;
    }
  }
}

.element:hover>.first>.element-drag-icon {
  opacity: 1;
}

.step {
  margin-top: 16px;
  font-size: 13px;
  margin-left: 24px;

  &-title {
    font-family: 'Roboto bold';
    margin-bottom: 6px;
  }

  &-description {
    font-family: 'Roboto medium';
    margin-bottom: 2px;
  }

  &-info {
    font-family: 'Roboto Light Italic';
    font-style: italic;
  }

  &-extension {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Roboto medium';

    >p {
      margin: 0 0 0 4px !important;
      font-family: 'Roboto Light Italic';
      font-style: italic;
    }
  }

  &-duration {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Roboto regular';
    font-size: 13px;
    margin-top: 6px;

    app-stepper {
      margin-left: 20px;
    }

  }
}

.p-tooltip-text {
  font-family: 'Roboto Italic';
  font-style: italic;
  font-style: italic;
  font-size: 13px;
}

.p-tooltip .p-tooltip-text {
  background-color: var(--ion-color-primary-bg) !important;
  color: var(--ion-color-dark) !important;
  -webkit-box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.5) !important;
}

.p-tooltip-arrow {
  border-color: transparent !important;
}

.p-tooltip {
  margin-top: -6px !important;
}

#tabViewThemeEditor {

  .p-tabview-panels {
    max-height: calc(100vh - 160px - 42px - 50px);
    overflow: auto;
  }
}

#phoneContainerBackgroundEditor {}

#dialog-add-template {
  .p-dialog-content {
    overflow: visible !important;
  }
}

#smallInputSwitch {

  .p-inputswitch {
    height: 1.2rem;
    width: 2.4rem;

    &.p-inputswitch-checked {
      .p-inputswitch-slider::before {
        transform: translateX(1.2rem);
      }
    }

    .p-inputswitch-slider {
      border-radius: 50px;
      box-shadow: none;

      &::before {
        top: 49%;
        left: 0.15rem;
        width: 0.9rem;
        height: 0.9rem;
        margin-top: -0.45rem;
        border-radius: 50px;
      }
    }

  }
}

#smallInputSwitchAlwaysActive {

  .p-inputswitch {
    height: 1.2rem;
    width: 2.4rem;

    &.p-inputswitch-checked {
      .p-inputswitch-slider::before {
        transform: translateX(1.15rem);
      }
    }

    .p-inputswitch-slider {
      background-color: #007bff !important;
      border-radius: 50px;
      box-shadow: none;

      &::before {
        // top: 49%;
        left: 0.15rem;
        width: 0.9rem;
        height: 0.9rem;
        margin-top: -0.45rem;
        border-radius: 50px;
      }
    }

  }
}


.mySwiperPrize {
  .swiper-slide {
    height: unset !important;
    max-height: unset !important;
  }
}

#calendar-mocli {
  .p-datepicker {
    border: none !important;
    border-radius: 15px !important;
  }

  .p-datepicker table td>span:focus {
    box-shadow: none !important;
  }

  .p-datepicker table td.p-datepicker-today>span {
    background-color: white;

    &:hover {
      background: #e9ecef;
    }
  }

  .p-datepicker table td.p-datepicker-today>span.p-highlight {
    background: #007bff !important;
  }

  .p-datepicker table td>span {
    border-radius: 10px;
  }
}

.accordionConditionsTab .p-accordion-tab .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none !important;
}

#autoSaveContainer {

  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none !important;
  }

  .p-inputswitch-slider {
    border-radius: 10px !important;

    &::before {
      border-radius: 10px !important;
    }
  }

  .p-inputswitch {
    height: 1.2rem;
    width: 2.4rem;

    &.p-inputswitch-checked {
      .p-inputswitch-slider::before {
        transform: translateX(1.15rem);
      }
    }

    .p-inputswitch-slider::before {
      width: 0.9rem;
      height: 0.9rem;
      left: 0.2rem;
      margin-top: -0.45rem;
    }
  }
}

#badgeContainer {
  p-Badge span {
    font-size: 0.8rem !important;
    height: 1.7rem !important;
    line-height: 1.7rem !important;
  }
}

#multiSelectExcludesRoles {
  .p-multiselect {
    min-width: 275px !important;
  }

  // .p-multiselect-header {
  //   display: none;
  // }
}