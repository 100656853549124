@font-face {
    font-family: 'Roboto regular';
    src:url('./Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto bold';
    src:url('./Roboto-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto black';
    src:url('./Roboto-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto light';
    src:url('./Roboto-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto thin';
    src:url('./Roboto-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto medium';
    src:url('./Roboto-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Italic';
    src:url('./Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Light Italic';
    src:url('./Roboto-LightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}